const ELEMENT = "[data-pipeline-row]";
const TRIGGER = "[data-pipeline-open]";
const OPENED_CLASS = "has-pipeline-opened";

export default class {
  constructor() {
    this.DOM = {
      elements: Array.from(document.querySelectorAll(ELEMENT)),
    };

    this.bindTriggersClick();
  }

  bindTriggersClick() {
    this.DOM.elements.forEach((element) => {
      const trigger = element.querySelector(TRIGGER);

      trigger.addEventListener("click", (ev) => {
        ev.stopPropagation();

        element.classList.toggle(OPENED_CLASS);
      });
    });
  }
}
