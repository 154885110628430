const TRIGGER = '[data-modal-open]'
const CONTENT = '[data-modal-content]'
const CLOSER = '[data-modal-close]'
const OPENED_CLASS = 'has-modal-opened'
const ROOT_CLASS = 'has-modal-active'

const HUNDRED = 100
const BP_LARGE = 1024

export default class {
  constructor() {
    this.DOM = {
      triggers: Array.from(document.querySelectorAll(TRIGGER)),
      closers: Array.from(document.querySelectorAll(CLOSER)),
    }

    this.bindTriggersClick()
    this.bindClosersClick()
    this.bindAnywhereClick()

    document.documentElement.addEventListener('touchstart', this.unbindPointerEvents)
  }

  bindTriggersClick() {
    this.DOM.triggers.forEach(trigger => {
      trigger.addEventListener('click', ev => {
        ev.stopPropagation()
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight
        const x = Math.floor(ev.clientX / viewportWidth * HUNDRED)
        const y = Math.floor(ev.clientY / viewportHeight * HUNDRED)
        const contentEl = trigger.querySelector(CONTENT)
        const isLarge = window.matchMedia(`(min-width: ${BP_LARGE}px)`).matches
        if (contentEl && !isLarge) {
          contentEl.style.transformOrigin = `${x}% ${y}%`
        }
        this.closeAll()
        trigger.classList.add(OPENED_CLASS)
        document.documentElement.classList.add(ROOT_CLASS)
      })
    })
  }

  bindClosersClick() {
    this.DOM.closers.forEach(closer => {
      closer.addEventListener('click', ev => {
        ev.stopPropagation()
        this.closeAll()
      })
    })
  }

  bindAnywhereClick() {
    document.documentElement.addEventListener('click', () => {
      this.closeAll()
    })
  }

  closeAll() {
    document.documentElement.classList.remove(ROOT_CLASS)
    document.querySelectorAll(`.${OPENED_CLASS}`).forEach(el => {
      el.classList.remove(OPENED_CLASS)
    })
  }
}
