const COMPONENT    = '[data-hamburger]'
const OPENED_CLASS = 'has-menu-opened'
const STICKY_CLASS = 'has-menu-sticky'

export default class {
  constructor() {
    this.DOM = {
      component: document.querySelector(COMPONENT)
    }

    this.bindHamburgerClick()
    this.bindDocumentScrollEvent()
  }

  bindHamburgerClick() {
    if (this.DOM.component) {
      this.DOM.component.addEventListener('click', () => {
        document.documentElement.classList.toggle(OPENED_CLASS)
      })
    }
  }

  bindDocumentScrollEvent() {
    window.addEventListener('scroll', () => {
      const html = document.documentElement
      const body = document.querySelector('body')

      if (html.scrollTop > 0 || body.scrollTop > 0) {
        this.stick()
      } else {
        this.unstick()
      }
    })
  }

  stick() {
    document.documentElement.classList.add(STICKY_CLASS)
  }

  unstick() {
    document.documentElement.classList.remove(STICKY_CLASS)
  }
}
